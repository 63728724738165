import L from 'leaflet';

export const showPositionOnMap = (map, userLocation) => { 
  if (userLocation == null) { return; }
  L.marker([userLocation.lat, userLocation.long], {
    icon: L.icon({
      iconUrl: '/images/crosshair.png', // Replace with your custom icon if needed
      iconSize: [38, 38],
      iconAnchor: [20, 20],
    }),
  }).addTo(map);
}


export const getCurrentPosition = (setUserLocation) => { 
    // Fetch and show the user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, long: longitude });
          // Fly to the user's location
          //leafletMap.setView([latitude, longitude], 14);
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  